/*

//// VARIABLES ////

    - Colors

*/

:root {
  /* Colors */
    --color-primary-hue: 355;
  --color-primary: hsl(var(--color-primary-hue), 72%, 42%);
  --color-primary-dark: hsl(var(--color-primary-hue), 71%, 37%);
  --color-primary-light: hsl(var(--color-primary-hue), 51%, 82%);

  --color-primary-100: hsl(var(--color-primary-hue), 100%, 97%);
  --color-primary-200: hsl(var(--color-primary-hue), 100%, 93%);
  --color-primary-300: hsl(var(--color-primary-hue), 100%, 88%);
  --color-primary-400: hsl(var(--color-primary-hue), 100%, 83%);
  --color-primary-500: hsl(var(--color-primary-hue), 100%, 78%);
  --color-primary-600: hsl(var(--color-primary-hue), 100%, 73%);
  --color-primary-700: hsl(var(--color-primary-hue), 100%, 68%);
  --color-primary-800: hsl(var(--color-primary-hue), 100%, 63%);
  --color-primary-900: hsl(var(--color-primary-hue), 100%, 58%);

  --color-wood-a: #DBBEA9;
  --color-wood-b: #E8D3C3;
  --color-wood-c: #F0DED0;
  --color-wood-d: #FAEEE5;
  --color-wood-e: #FEF7F1;

  --color-dark: #1F1010;
  --color-grey: #787070;
  --color-light: var(--color-wood-e);

  --color-cta-yellow: #FCD718;
  --color-cta-green-light: #86d7ac;
  --color-cta-green-dark: #1d7d4a;

  --color-background: #e6dddd;

  --box-color-dark: #1d1d1d;
  --box-color-light: #ffffff;

  /* Fonts */

  /* Sizes */
  --ratio: 1.5;
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: 1rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));

  --border-thin: 1px;
  --header-height: 60px;

  /* //////////////////////////////////////////////////////////////////////////// */
  /* Max width of all elements except for some containers */
  --measure: 60ch;

  --cover-height: 90vh;

  /* //////////////////////////////////////////////////////////////////////////// */
  /* Inputs */

  --input-border: #8b8a8b;
  --input-focus-h: 355;
  --input-focus-s: 100%;
  --input-focus-l: 32%;
}
