.pagination-container {
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin-top: 25px;
  width: 75%;
}

.pagination a {
  padding: 3px 8px;
  border-radius: 5px;
  border: 1px solid #ba1e2b;
  color: #ba1e2b;
}

.pagination a:hover {
  text-decoration: none;
}

.pagination-link {
  font-weight: bold;
  cursor: pointer;
}

.pagination-link-active a {
  color: #fff !important;
  background: #ba1e2b;
}

.pagination-link-disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

.pagination-link-disabled a:hover {
  cursor: no-drop;
}

.loader-container {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
