.header-bg{
    background-color: #BA1E2B;
}

.navbar {
    background-color: #BA1E2B;
}

.navbar-item {
    color: #FFF !important;
}

